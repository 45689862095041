import * as React from "react";
import {Helmet} from "react-helmet";
import "normalize.css";
import "../styles/main.css";
import styled, {css} from "styled-components";
import {Group} from "../components/GroupsPage/Group";
import {GroupsHeader} from "../components/GroupsPage/GroupsHeader";
import {FilterHeader} from "../components/GroupsPage/Filter/styles";
import {useEffect, useMemo, useState} from "react";
import Img from "gatsby-image";
import axios from "axios";
//
// export const query = graphql`
//     query GroupPageQuery {
//         arrow: file(relativePath: { eq: "back-arrow.png" }) {
//             childImageSharp {
//                 fixed(width: 10) {
//                     ...GatsbyImageSharpFixed_withWebp
//                 }
//             }
//         },
//         allContentfulGroups(sort: {fields: [availableSeats, updatedAt], order: [DESC, ASC]}) {
//             nodes {
//                 id
//                 hosts {
//                     internal {
//                         content
//                     }
//                 }
//                 hostImages {
//                     id
//                     fluid(maxWidth: 100) {
//                         ...GatsbyContentfulFluid_withWebp
//                     }
//                     title
//                 }
//                 type {
//                     id
//                     name
//                     color
//                 }
//                 day {
//                     id
//                     name
//                 }
//                 time
//                 place {
//                     id
//                     name
//                 }
//                 additionalInfo
//                 availableSeats
//                 maxSeats
//                 form
//             }
//         }
//
//         allContentfulDays(sort: {fields: sort}) {
//             nodes {
//                 name
//                 id
//             }
//         }
//
//         allContentfulTypes {
//             nodes {
//                 id
//                 name
//                 color
//             }
//         }
//
//         allContentfulPlaces {
//             nodes {
//                 id
//                 name
//             }
//         }
//     }
// `

const PageBackground = styled.div`
  background: rgb(102, 46, 155);
  background: linear-gradient(145deg, rgba(102, 46, 155, 1) 0%, rgba(67, 188, 205, 1) 100%);
`

const Content = styled.div`
  max-width: 92.5rem;
  margin: 0 auto;
  padding: 0 7rem;
  
  @media (max-width: 60rem) {
    padding: 0 1rem;
  }
`

const FilterContainer = styled.div`
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr;
  margin-bottom: 2rem;
  grid-gap: 1rem;

  @media (max-width: 75rem) {
    grid: auto / 1fr 1fr;
  }
  
  @media (max-width: 60rem) {
    grid: auto / 1fr;
  }
`

const FilterStyledElement = styled.div`
  border: 4px solid #fff;
  width: 100%;
  height: fit-content;
  border-radius: 1.25rem;
`

const FilterStyledElementWhiteBG = styled(FilterStyledElement)`
  background-color: #fff;
`

const FilterElementHeader = styled.div`
  padding: 1rem;
  font-size: 1.55rem;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
`

const FilterElementHeaderFilter = styled(FilterElementHeader)`
  color: #5475B4;
`

const FilterElementContent = styled.div`
  padding: 1rem;
  display: grid;
  grid: auto / 1fr 1fr;
  grid-gap: 1rem;

  ${props => props.hidden && css`
    display: none;
  `}
`

const FilterContentItem = styled.div`
  height: 3.5rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
  border: 3px solid rgba(255, 255, 255, .4);
  background-color: ${props => props.bgColor || "transparent"};

  ${props => props.selected && css`
    border-color: rgba(255, 255, 255, 1);
  `}
`

const MoreArrowInButton = styled.div`
  transform: rotate(270deg);
  margin-left: 1rem;

  ${props => props.active && css`
    transform: rotate(90deg);
  `}
`;

const FiltersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: 5px solid rgba(255, 255, 255, .4);
  padding: 1rem 1rem 0;
  border-radius: 10px;
`

const ListFilterItem = styled(FilterContentItem)`
  width: fit-content;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 1rem 1rem 0;
  position: relative;

  &::after {
    content: 'x';
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    top: -0.5rem;
    right: -0.5rem;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
  }
`

const ClearFiltersButton = styled.a`
  margin-top: 2rem;
  font-size: 1.5rem;
  text-decoration: underline;
  margin-bottom: 1rem;
  display: block;
  cursor: pointer;
`

const GroupsContainer = styled.div`
  display: grid;
  grid: auto / 1fr 1fr;
  grid-gap: 3rem;
  margin-top: 4rem;
  
  @media (max-width: 75rem) {
    grid: auto / 1fr;
    grid-gap: 2rem;
  }
`


export const NotFoundMessageContainer = styled.div`
  text-align: center;
  padding: 4rem 0 10rem;
`

export const NotFoundMessageTitle = styled.div`
  font-size: 1.1rem;

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
`

export const NotFoundMessageText = styled.div`
  font-size: 1rem;
  font-weight: 400;
  
  a {
    font-weight: 600;
  }

  @media (min-width: 1024px) {
    font-size: 1.2rem;
  }
`

const GroupsPage = ({data}) => {
  // const groups = data.allContentfulGroups.nodes;
  // const days = data.allContentfulDays.nodes;
  // const types = data.allContentfulTypes.nodes;
  // const places = data.allContentfulPlaces.nodes;

  const [filterDays, setFilterDays] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);
  const [filterPlaces, setFilterPlaces] = useState([]);
  const [enabledFilterType, setEnabledFilterType] = useState(null);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    axios.get('https://www.grupy-radosci.pl/api/v1/groups').then((data) => {
      if (data?.data) {
        console.log(data.data)
        setGroups(data.data);
      }
    });
  }, [])

  const filteredGroups = useMemo(() => {
    return groups.filter(group => {
        // let inDays = true;
        // let inTypes = true;
        // let inPlaces = true;
        //
        // if (filterDays.length > 0) {
        //   inDays = filterDays.some((type) => {
        //     return type === group.day.id
        //   })
        // }
        //
        // if (filterTypes.length > 0) {
        //   inTypes = group?.type.some(groupType => filterTypes.some((type) => type === groupType.id))
        // }

        // if (filterPlaces.length > 0) {
        //   inPlaces = filterPlaces.some((type) => {
        //     return type === group.place.id
        //   })
        // }

        // return inDays && inTypes && inPlaces;
        return group.visible;
      }
    )
  }, [groups, filterDays, filterTypes, filterPlaces])

  const setFilterUtil = (stateHook, setStateHook, item) => {
    stateHook.includes(item) ?
      setStateHook(state => state.filter((stateItem) => stateItem !== item)) :
      setStateHook(state => [...state, item]);
  }

  const setFilter = (id, item) => {
    switch (id) {
      case 'day':
        setFilterUtil(filterDays, setFilterDays, item);
        break;
      case 'type':
        setFilterUtil(filterTypes, setFilterTypes, item)
        break;
      case 'place':
        setFilterUtil(filterPlaces, setFilterPlaces, item)
        break;
    }
  }

  const removeFilter = (id) => {
    setFilterDays(state => state.filter((stateItem) => stateItem !== id))
    setFilterTypes(state => state.filter((stateItem) => stateItem !== id))
    setFilterPlaces(state => state.filter((stateItem) => stateItem !== id))
  }

  const FilterElement = ({filterId, name, itemData}) => {
    return (
      <FilterStyledElement key={itemData.id}>
        <FilterElementHeader onClick={() => setEnabledFilterType(state => state === filterId ? null : filterId)}>
          {name}
          <MoreArrowInButton active={enabledFilterType === filterId}>
            <Img fixed={data.arrow.childImageSharp.fixed}/>
          </MoreArrowInButton>
        </FilterElementHeader>
        <FilterElementContent hidden={enabledFilterType !== filterId}>
          {itemData && itemData.map(item => {
            return (
              <>
                <FilterContentItem
                  key={item.id}
                  onClick={() => setFilter(filterId, item.id)}
                  bgColor={item.color}
                  selected={
                    filterDays.some(filter => filter === item.id) ||
                    filterTypes.some(filter => filter === item.id) ||
                    filterPlaces.some(filter => filter === item.id)
                  }>
                  {item.name}
                </FilterContentItem>
              </>
            )
          })}
        </FilterElementContent>
      </FilterStyledElement>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Grupy Radości - Kościół Radość Życia</title>
        <link rel="stylesheet" href="https://use.typekit.net/rjh5hkz.css"/>
      </Helmet>
      <main>
        <PageBackground>
          <Content>
            <GroupsHeader/>
            <FilterHeader>
              Dostępne<br/>grupy
            </FilterHeader>
            <FilterContainer>
              <FilterStyledElementWhiteBG>
                <FilterElementHeaderFilter>
                  Filtr ({filterDays.length + filterTypes.length + filterPlaces.length})
                </FilterElementHeaderFilter>
              </FilterStyledElementWhiteBG>
              {/*{FilterElement({filterId: 'day', name: 'Dzień', itemData: days})}*/}
              {/*{FilterElement({filterId: 'type', name: 'Rodzaj', itemData: types})}*/}
              {/*{FilterElement({filterId: 'place', name: 'Miejsce', itemData: places})}*/}
            </FilterContainer>
            {!![...filterDays, ...filterTypes, ...filterPlaces].length && (
              <>
                <ClearFiltersButton onClick={() => {
                  setFilterDays([])
                  setFilterTypes([])
                  setFilterPlaces([])
                }}>wyczyść filtry</ClearFiltersButton>
                <FiltersList>
                  {[...filterDays, ...filterTypes, ...filterPlaces].map(enabledFilter => {
                    // const f = [...days, ...types, ...places].find((item => item.id === enabledFilter));
                    // return f && (
                    //   <ListFilterItem
                    //     key={f.id}
                    //     onClick={() => removeFilter(f.id)}
                    //     bgColor={f.color}
                    //     selected>
                    //     {f.name}
                    //   </ListFilterItem>
                    // )
                  })}
                </FiltersList>
              </>
            )}
            <GroupsContainer>
              {filteredGroups && filteredGroups.map(group => {
                return (
                  <Group key={group.id} group={group} data={{}}/>
                )
              })}
            </GroupsContainer>
            <NotFoundMessageContainer>
              <NotFoundMessageTitle>
                Nie znalazłeś tego, czego szukasz?
              </NotFoundMessageTitle>
              <NotFoundMessageText>
                Dołącz do innej grupy i daj się zaskoczyć lub <a style={{color: "#fff"}} target={"_blank"} href="https://forms.gle/A5CvG29SAdrWMbQq7">otwórz własną grupę</a> i odpowiedz na potrzebę.
              </NotFoundMessageText>
            </NotFoundMessageContainer>
          </Content>
        </PageBackground>
      </main>
    </React.Fragment>
  );
};

export default GroupsPage;
