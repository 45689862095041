import styled from "styled-components";

export const FilterHeader = styled.h2`
  font-size: 3.625rem;
  padding: 0;
  margin: 0 0 3rem;
  color: #fff;
  font-weight: 700;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  position: relative;
  line-height: 1;

  @media (min-width: 1024px) {
    font-size: 7.5rem;
    margin: 0 0 4rem;
  }
`
