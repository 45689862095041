import React from 'react'
import {
  BackButton,
  FindGroupImageContainer,
  FindGroupPoint, FindGroupPointLeft,
  FindGroupPointRight,
  FindGroupPointsContainer, FindGroupPointText, FindGroupPointTitle,
  HeaderH1
} from "./styles";
import {FindGroupContainer} from "./styles";
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";

export const GroupsHeader = () => {
  return (
    <StaticQuery
      query={graphql`
        query FindGroupQuery {
          heroImg: file(relativePath: { eq: "wartosciradosczycia.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          backArrow: file(relativePath: { eq: "back-arrow.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <div>
          <BackButton to={'/'}>
            <Img fluid={data.backArrow.childImageSharp.fluid}/>
          </BackButton>
          <HeaderH1>
            Znajdź swoją Grupę
          </HeaderH1>
          <FindGroupContainer>
            <FindGroupImageContainer>
              <Img fluid={data.heroImg.childImageSharp.fluid}/>
            </FindGroupImageContainer>
            <FindGroupPointsContainer>
              <FindGroupPointLeft>
                01
              </FindGroupPointLeft>
              <FindGroupPointRight>
                <FindGroupPointTitle>
                  Wybierz Grupę
                </FindGroupPointTitle>
                <FindGroupPointText>
                  Istnieją różne rodzaje grup zależnie od grupy wiekowej, płci, relacji, lokalizacji i terminu spotkań.
                  Skorzystaj z filtra i wybierz grupę, która jest odpowiednia dla ciebie.
                </FindGroupPointText>
              </FindGroupPointRight>
              <FindGroupPointLeft>
                02
              </FindGroupPointLeft>
              <FindGroupPointRight>
                <FindGroupPointTitle>
                  Zapisz się
                </FindGroupPointTitle>
                <FindGroupPointText>
                  Kliknij w przycisk, wypełnij formularz i czekaj na maila zwrotnego ze szczegółowymi informacjami
                  dotyczącymi spotkania. Zapisy możliwe do 24 godzin przed spotkaniem.
                </FindGroupPointText>
              </FindGroupPointRight>
              <FindGroupPointLeft>
                03
              </FindGroupPointLeft>
              <FindGroupPointRight>
                <FindGroupPointTitle>
                  Przyjdź na chwilę
                  lub zostań na dłużej
                </FindGroupPointTitle>
                <FindGroupPointText>
                  Każdy sezon grup trwa trzy miesiące. Docelowo warto na ten czas stać się częścią konkretnej grupy,
                  jednak nie musisz robić tego od razu. Daj sobie czas na poszukiwania. W każdej chwili możesz zacząć
                  szukać innej lub zrezygnować.
                </FindGroupPointText>
              </FindGroupPointRight>
            </FindGroupPointsContainer>
          </FindGroupContainer>
        </div>
      )
      }
    />
  )
}
