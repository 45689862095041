import React, {useEffect, useState} from 'react'
import {
  AdditionalInfoContainer,
  GroupAvailableSeats,
  GroupContainer,
  GroupDetails,
  GroupDetailsContainer, GroupDetailsDate, GroupDetailsDateAndExtraContainer, GroupDetailsExtraInfo, GroupDetailsPlace,
  GroupDetailsType,
  GroupHosts, GroupHostsImages, GroupJoinButton, GroupJoinButtonArrow, GroupJoinContainer,
  GroupTitle,
  GroupType
} from "./styles";
import * as PropTypes from "prop-types";
import Img from "gatsby-image";
import {graphql, StaticQuery} from "gatsby";
import axios from "axios";

GroupTitle.propTypes = {children: PropTypes.node};
export const Group = ({group, data}) => {
  // const days = data.days;
  // const types = data.types;
  // const places = data.places;

  // const groupTypes = types.filter(type => group.type.find(gType => gType.id === type.id));
  // const groupDay = days.find(day => day.id === group.day.id);
  // const groupPlace = places.find(place => place.id === group.place.id);

  const [hosts, setHosts] = useState([]);
  const [hostImages, setHostImages] = useState([]);
  const [place, setPlace] = useState();
  const [targets, setTargets] = useState([]);
  const [availableSeats, setAvailableSeats] = useState('');


  useEffect(() => {
    axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/users`).then((data) => {
      if (data?.data) {
        setHosts(data.data);
      }
    });

    axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/places`).then((data) => {
      if (data?.data) {
        setPlace(data.data?.name);
      }
    })
    axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/targets`).then((data) => {
      if (data?.data) {
        setTargets(data.data);
      }
    })
    axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/participants`).then((data) => {
      if (data?.data) {
        setAvailableSeats(data.data?.count);
      }
    })
  }, [])

  useEffect(() => {
    if (hosts) {
      const newHostImages = [];
      const promises = [];

      hosts.forEach((host) => {
        promises.push(axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/users/${host.id}`));
      });

      Promise.all(promises).then(function (results) {
        results.forEach(function (response) {
          const image = response.data.url;
          newHostImages.push(image);
        });

        setHostImages(newHostImages);
      });
    }
  }, [hosts])

  const dayOfWeek = () => {
    switch (group.day_of_week) {
      case 'monday':
        return 'Poniedziałek'
      case 'tuesday':
        return 'Wtorek'
      case 'wednesday':
        return 'Środa'
      case 'thursday':
        return 'Czwartek'
      case 'friday':
        return 'Piątek'
      case 'saturday':
        return 'Sobota'
      case 'sunday':
        return 'Niedziela'
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query GroupQuery {
          arrow: file(relativePath: { eq: "back-arrow.png" }) {
            childImageSharp {
              fluid(maxWidth: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <GroupContainer>
          <GroupTitle>
            {hosts.map((host) => (
              <>
                {host.first_name}
                {' '}
                {host.last_name}
                <br/>
              </>
            ))}
          </GroupTitle>
          <GroupDetailsContainer>
            <GroupDetailsType>
              {targets?.name && targets?.name.map((type, index) => (
                <GroupType bgColor={targets.color[index]}>
                  {type}
                </GroupType>
              ))}
            </GroupDetailsType>
            <GroupDetails>
              <GroupDetailsDateAndExtraContainer>
                <GroupDetailsDate>
                  {dayOfWeek()} - {group.hour}
                </GroupDetailsDate>
                <GroupDetailsPlace>
                  {place}
                </GroupDetailsPlace>
              </GroupDetailsDateAndExtraContainer>
              {group.additionalInfo && (
                <AdditionalInfoContainer>
                  <span>|</span>
                  <GroupDetailsExtraInfo>
                    {group.additionalInfo}
                  </GroupDetailsExtraInfo>
                </AdditionalInfoContainer>
              )}
            </GroupDetails>
          </GroupDetailsContainer>
          <GroupHosts>
            <GroupHostsImages>
              {hostImages && hostImages.map((image, index) => <img key={index} src={image} alt=''/>)}
            </GroupHostsImages>
            <GroupJoinContainer>
              {availableSeats && (availableSeats !== '0/10') ? (
                <GroupJoinButton href={`/formularz/${group.id}`} >
                  Dołącz
                  <GroupJoinButtonArrow>
                    <Img fluid={data.arrow.childImageSharp.fluid}/>
                  </GroupJoinButtonArrow>
                </GroupJoinButton>
              ) : null}
              <GroupAvailableSeats>
                {availableSeats && availableSeats !== '0/10' ? (
                  <>
                    Dostępnych miejsc: {availableSeats}
                  </>
                ) : <div>Aktualnie brak dostępnych miejsc</div>}
              </GroupAvailableSeats>
            </GroupJoinContainer>
          </GroupHosts>
        </GroupContainer>
      )}
    />
  )
}
