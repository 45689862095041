import styled from "styled-components";

export const GroupContainer = styled.div`
  border: 5px solid rgba(255, 255, 255, .4);
  border-radius: 2.5rem;
  padding: 1.5rem;
  display: grid;
  grid: 1fr auto / 1fr;


  @media (min-width: 1024px) {
    grid: 1fr auto 1fr / 1fr;
  }
`

export const GroupTitle = styled.h2`
  font-size: 1.56rem;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
`

export const GroupDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: .5rem 0;
  width: 100%;

  @media (min-width: 375px) {
    flex-direction: row;
    align-items: center;
  }
`

export const GroupDetailsType = styled.div`
  display: grid;
  align-items: center;
  width: 7rem;
  
  @media (max-width: 60rem) {
    width: 5rem;
  }
`

export const GroupType = styled.div`
  padding: 0 .2rem;
  font-size: .8rem;
  width: 100%;
  min-height: 2.5rem;
  background-color: ${props => props.bgColor || "transparent"};
  border-radius: 10px;
  text-align: center;
  margin: .25rem 0;
  border: 3px solid rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GroupDetails = styled.div`
  display: flex;
  font-size: 1rem;
  width: 100%;
  align-items: stretch;

  @media (min-width: 375px) {
    margin-left: 1rem;
  }
`

export const GroupDetailsDateAndExtraContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const GroupDetailsDate = styled.div`
  margin-right: .5rem;
  white-space: nowrap;
  //flex-grow: 2;
`

export const GroupDetailsPlace = styled.div`
  font-weight: 500;
`

export const AdditionalInfoContainer = styled.div`
  display: flex;
`

export  const GroupDetailsExtraInfo = styled.div`
  margin-left: .5rem;
  font-weight: 400;
  font-style: italic;
`

export const GroupHosts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1rem;

  @media (min-width: 375px) {
    flex-direction: row;
    align-items: center;
  }
`

export const GroupHostsImages = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  
  img {
    margin-right: .5rem;
    width: 4rem;
    height: 4rem;
  }
`

export const GroupJoinContainer = styled.div`
  
`
export const GroupJoinButton = styled.a`
  font-size: 1.75rem;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom: 2px solid #fff;
  border-bottom: 4px solid #fff;

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
  
  .gatsby-image-wrapper {
    transform: rotate(180deg);
  }
`
export const GroupJoinButtonArrow = styled.div`
  width: .8rem;
  height: 1rem;
  margin-left: 1rem;
  margin-bottom: .5rem;
`

export const GroupAvailableSeats = styled.div`
  color: rgba(255, 255, 255, .6);
  font-weight: 400;
  font-style: italic;
  margin-top: .5rem;
  
  @media (max-width: 60rem) {
    font-size: .8rem;
  }
`
